<template>
  <section id="contact" ref="contact" class="pt-64 mb-64 container relative mx-2 lg:mx-auto">
    <h2>Contact <span class="text-gold">Us</span></h2>

    <div class="contact-form">
      <div class="bg-red-100 text-red-500 border-red-500 border rounded my-8 p-4" v-if="contactResponse.status == 'validation_failed'">
        {{ contactResponse.message }}
      </div>
      <div class="bg-green-100 text-green-500 border-green-500 border rounded my-8 p-4" v-if="contactResponse.status == 'mail_sent'">
        {{ contactResponse.message }}
      </div>
      <div class="flex flex-col lg:flex-row gap-x-6">
        <div class="flex flex-col flex-1">
          <div class="relative mb-4">
            <input type="text" v-model="contactForm.name" placeholder="Name" id="name" required :class="{ 'error': contactErrors.name }" @change="validate()" />
            <label for="name">Name</label>
          </div>
          <div class="relative mb-4">
            <input type="email" v-model="contactForm.email" placeholder="Email" id="email" required :class="{ 'error': contactErrors.email }" @change="validate()" />
            <label for="email">Email</label>
          </div>
          <div class="relative mb-4">
            <input type="text" v-model="contactForm.phone" placeholder="Phone" id="phone" />
            <label for="phone">Phone</label>
          </div>
        </div>
        <div class="flex flex-col flex-1">
          <textarea type="text" v-model="contactForm.message" placeholder="Message" id="message" class="h-full mb-4" required  :class="{ 'error': contactErrors.message }" @change="validate()"></textarea>
          <label for="message">Message</label>
        </div>
      </div>
    </div>

    <div class="block justify-right float-right mb-12">
      <button class="button cursor-pointer" @click="send()">
        <span>Send</span>
        <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
      </button>
    </div>

    <div class="flex flex-col lg:flex-row contact-details mt-32 justify-between">
      <div class="flex flex-col text-left mb-12">
        <p><i class="fa fa-building"></i>Inluxus Srl</p>
        <p><i class="fa fa-envelope"></i>concierge@inluxusfdm.com</p>
        <p class="flex">
          <i class="fa fa-map-pin"></i>
          <span class="leading-7">
            Berkeley Suite <br />
            35 Berkeley Square <br />
            Mayfair <br />
            London <br />
            W1J 5BF <br />
            UK
          </span>
        </p>
      </div>

      <div class="flex flex-col text-left mb-12">
        <p class="flex">
          <i class="fa fa-user"></i>
          <span class="lg:h-14">
            Alessandra Casalini <br />
            <span class="title">CEO Inluxus</span>
          </span>
        </p>
        <p><i class="fa fa-phone"></i><a href="tel:+393668914432">+39 366 891 4432</a></p>
        <p><i class="fa fa-envelope"></i><a href="mailto:ac@inluxusfdm.com">ac@inluxusfdm.com</a></p>
      </div>

      <div class="flex flex-col text-left mb-12">
        <p class="flex">
          <i class="fa fa-user"></i>
          <span class="lg:h-14">
            Armando Camacho <br />
            <span class="title">Inluxus Chief Business Officer</span><br />
            <span class="title">Global Director</span>
          </span>
        </p>
        <p><i class="fa fa-phone"></i><a href="tel:+393311521733">+39 331 152 1733</a></p>
        <p><i class="fa fa-envelope"></i><a href="mailto:acp@inluxusfdm.com">acp@inluxusfdm.com</a></p>
      </div>

      <div class="flex flex-col text-left mb-12">
        <p class="flex">
          <i class="fa fa-user"></i>
          <span class="lg:h-14">
            Iryna Zayats <br />
            <span class="title">Europe &amp; Russia Director</span>
          </span>
        </p>
        <p><i class="fa fa-phone"></i><a href="tel:+393899497593">+39 389 949 7593</a></p>
        <p><i class="fa fa-envelope"></i><a href="mailto:iz@inluxusfdm.com">iz@inluxusfdm.com</a></p>
      </div>

      <!-- <div class="flex flex-col text-left">
        <p><i class="fab fa-whatsapp"></i><a href="https://wa.me/391234567890">+39 1234 567890</a></p>
        <p><i class="fab fa-telegram"></i><a href="https://telegram.me/inluxus">inluxus</a></p>
        <p><i class="fab fa-linkedin"></i><a href="https://linkedin.com/inluxus">inluxus</a></p>
      </div> -->
    </div>

    <!-- <span class="contact-bubble bg-bubble-email" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fa fa-envelope"></i></span>
    <span class="contact-bubble bg-bubble-phone" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fa fa-phone"></i></span>
    <span class="contact-bubble bg-bubble-facebook" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fab fa-facebook"></i></span>
    <span class="contact-bubble bg-bubble-twitter" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fab fa-twitter"></i></span>
    <span class="contact-bubble bg-bubble-telegram" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fab fa-telegram"></i></span>
    <span class="contact-bubble bg-bubble-whatsapp" :style="{ left: randomLeft() + '%', 'animation-delay': randomDelay() + 's' }"><i class="fab fa-whatsapp"></i></span> -->
  </section>
</template>

<script>
import Vue from "vue";
import axios from 'axios'
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LeX1AoeAAAAAG4ny_qqEV80RB1Tq_JA8Uej2atb" });

export default {
  name: 'ContactForm',
  data() {
    return {
      contactErrors: {},
      contactForm: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      contactResponse: {},
      contentUrl: 'https://inluxus.content.netlocomotion.com',
    }
  },
  methods: {
    randomLeft: function() {
      return Math.random() * 80;
    }, // /randomLeft
    randomDelay: function() {
      return Math.random() * 7;
    }, // /randomDelay
    send: function() {
      const formData = new FormData();
      var self = this;
      formData.append('your-name', this.contactForm.name);
      formData.append('your-email', this.contactForm.email);
      formData.append('your-phone', this.contactForm.phone);
      formData.append('your-message', this.contactForm.message);

      this.$recaptcha("login").then((token) => {
        formData.append("_wpcf7_recaptcha_response", token);

        // Send the contact form request
        axios.post(this.contentUrl + '/wp-json/contact-form-7/v1/contact-forms/14/feedback', formData)
        .then(function (result) {
          self.contactResponse = result.data;
        }, function (error) {
          self.contactResponse = error.data;
          console.log(error);
        });
      });

    }, // /send
    validate: function() {
      // Name
      if (this.contactForm.name.length == 0) {
        this.contactErrors.name = true;
      } else {
        this.contactErrors.name = false;
      }

      // Email
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      console.log(re.test(this.contactForm.email));
      this.contactErrors.email = !re.test(this.contactForm.email);

      // Message
      if (this.contactForm.message.length == 0) {
        this.contactErrors.message = true;
      } else {
        this.contactErrors.message = false;
      }



      console.log(this.contactErrors.length);
    }, // /validate
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contact input,
#contact textarea {
  @apply bg-grey-400;
  border: 1px solid;
  @apply border-grey-100;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  color: #fff;
  width: 100%;
}

#contact textarea {
  height: 100%;
}

#contact label {
  visibility: hidden;
  position: absolute;
  left: 15px;
  color: #a7a7a7;
  font-size: small;
  top: 4px;
}

#contact input::placeholder,
#contact textarea::placeholder {
  @apply text-grey-100;
}

#contact input:not(:placeholder-shown),
#contact textarea:not(:placeholder-shown) {
  padding-bottom: 10px;
  padding-top: 22px;
}

#contact input:not(:placeholder-shown) + label,
#contact textarea:not(:placeholder-shown) + label {
  visibility: visible;
}

#contact .contact-details p {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

#contact .contact-details a:hover {
  text-decoration: underline;
}

#contact .contact-details svg {
  width: 3.5rem;
}

#contact .title {
	font-variant: small-caps;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
}

.contact-bubble {
	color: #f0f0f0;
	border-radius: 100%;
	padding: 1rem;
	display: flex;
	height: 6rem;
	width: 6rem;
	justify-content: center;
	align-items: center;
	font-size: 4rem;
  position: absolute;
  bottom: 0%;
  opacity: 0;
  animation: fadeIn 2s linear alternate infinite, rise 4s linear normal infinite;
  z-index: -1;
}

#contact input.error {
	border: 2px solid RED;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

// // FontAwesome
// import { library, dom } from '@fortawesome/fontawesome-svg-core'
// // import {
// //   faUser,
// //   faBuilding,
// //   faEnvelope,
// //   faArrowRight,
// //   faBars,
// //   faShieldAlt,
// //   faHandshake,
// //   faUserTie,
// //   faClock,
// //   faQuestion,
// //   faComment,
// //   faPhone,
// //   faMapPin,
// //   faChevronRight,
// //   faTimes,
// //   faLayerGroup,
// // } from '@fortawesome/free-solid-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import {
//   faFacebook,
//   faInstagram,
//   faTwitter,
//   faLinkedin,
//   faWhatsapp,
//   faTelegram,
// } from '@fortawesome/free-brands-svg-icons'
// library.add(
//   // faUser,
//   // faBuilding,
//   // faEnvelope,
//   // faArrowRight,
//   // faBars,
//   // faShieldAlt,
//   // faHandshake,
//   // faUserTie,
//   // faClock,
//   // faQuestion,
//   // faComment,
//   // faPhone,
//   // faMapPin,
//   // faChevronRight,
//   // faTimes,
//   // faLayerGroup,
//   fas,
//   faFacebook,
//   faInstagram,
//   faTwitter,
//   faLinkedin,
//   faWhatsapp,
//   faTelegram,
// );
// dom.watch()

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

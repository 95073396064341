<template>
  <div class="blogs">
    <div id="hero-section" class="w-full lg:w-11/12 mx-auto" :style="{ 'background-image': 'url(' + blogImg + ')' }">
      <!-- <div class="hero-overlay"></div> -->
      <div class="hero-text pl-4 lg:pl-12 pt-24">
        <h1>{{ blog.title.rendered }}</h1>
      </div>
    </div>

    <section id="blog" ref="blog" class="pt-4 container mx-2 lg:mx-auto">
        <div>
          <p><i>{{ new Date(blog.date).toDateString() }}</i></p>
          <span v-html='blog.excerpt.rendered'></span>
        </div>


        <h3 class="mt-16">More from the blog</h3>
        <div class="content grid grid-cols-1 lg:grid-cols-3 my-12 pb-2 gap-6">
          <div class="flex-1 lg:order-1 blog-content" v-for="blog in moreBlogs" :key="blog.id">
            <a :href="'/blog/' + blog.slug">
              <div>
                <img
                v-if="blog._embedded['wp:featuredmedia'] && blog._embedded['wp:featuredmedia'].length > 0"
                :src="blog._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url"
                :alt="blog._embedded['wp:featuredmedia'][0].alt_text"
                class="w-auto mb-8"
                />
                <h3>{{ blog.title.rendered }}</h3>
                <div class="block float-right">
                  <button class="button button-secondary">
                    <span>Read More</span>
                    <span><i class="fa fa-arrow-right"></i></span>
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
    </section>


    <ContactForm />


  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'BlogView',
  data() {
    return {
      animated: {
      },
      blog: {
        title: {
          rendered: ''
        },
        date: '',
        excerpt: {
          rendered: ''
        },
      },
      blogImg: '',
      contactForm: {},
      contentUrl: 'https://inluxus.content.netlocomotion.com',
      moreBlogs: [],
    }
  },
  components: {
    ContactForm,
  },
  mounted() {
    window.scrollTo(0,0);
    this.getBlogPosts();
    this.morePosts();
    // window.addEventListener('scroll', this.scrollEvents);
  },
  methods: {
    getBlogPosts: function() {
      fetch(this.contentUrl + '/wp-json/wp/v2/posts?slug=' + this.$route.params.slug + '&_embed').then(response => response.json())
        .then((data) => {
          if (data !== undefined) {
            this.blog = data[0];
          }

          if (data[0]._embedded['wp:featuredmedia'] !== undefined) {
            this.blogImg = data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
          }
        });
    }, // /getBlogPosts
    morePosts: function() {
      fetch(this.contentUrl + '/wp-json/wp/v2/posts?per_page=3&_embed').then(response => response.json())
        .then((data) => {
          this.moreBlogs = data;
        });
    }, // /morePosts
    randomLeft: function() {
      return Math.random() * 80;
    }, // /randomLeft
    randomDelay: function() {
      return Math.random() * 7;
    }, // /randomDelay
    scrollEvents: function() {
      // var halfScreen = window.innerHeight/2;

    }, // /scrollEvents
  },
}
</script>

<style>
section h2 {
  color: #fff;
  font-family: Merriweather, serif;
  font-size: 3rem;
  @apply mb-6;
  text-align: center;
}

section h3, section p {
  @apply text-grey-200;
  @apply mb-6;
}

section p {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  line-height: 21px;
}

#blog h3 {
	text-align: center;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  color: #fff;
  text-transform: uppercase;
}

#blog .blog-content {
  @apply text-justify;
  @apply px-6;
  @apply pb-6;
  @apply bg-grey-300;
  border-radius: 50px;
}

#blog .blog-content {
  @apply p-8;
}

#blog .blog-content img {
  border-radius: 50px;
}

#contact input,
#contact textarea {
  @apply bg-grey-400;
  border: 1px solid;
  @apply border-grey-100;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  color: #fff;
  width: 100%;
}

#contact textarea {
  height: 100%;
}

#contact label {
  visibility: hidden;
  position: absolute;
  left: 15px;
  color: #a7a7a7;
  font-size: small;
  top: 4px;
}

#contact input::placeholder,
#contact textarea::placeholder {
  @apply text-grey-100;
}

#contact input:not(:placeholder-shown),
#contact textarea:not(:placeholder-shown) {
  padding-bottom: 10px;
  padding-top: 22px;
}

#contact input:not(:placeholder-shown) + label,
#contact textarea:not(:placeholder-shown) + label {
  visibility: visible;
}

#contact .contact-details p {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

#contact .contact-details a:hover {
  text-decoration: underline;
}

#contact .contact-details svg {
  width: 3.5rem;
}

.contact-bubble {
	color: #f0f0f0;
	border-radius: 100%;
	padding: 1rem;
	display: flex;
	height: 6rem;
	width: 6rem;
	justify-content: center;
	align-items: center;
	font-size: 4rem;
  position: absolute;
  bottom: 0%;
  opacity: 0;
  animation: fadeIn 2s linear alternate infinite, rise 4s linear normal infinite;
  z-index: -1;
}

@keyframes fadeIn {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@keyframes rise {
  0%    { bottom: 0%; }
  100%  { bottom: 100%; }
}

.additional-services img:hover {
  animation: bobble 0.8s linear 10ms infinite;
}
</style>

<template>
  <div>
    <div id="nav-wrapper" class="fixed w-screen inset-0">
      <nav id="nav" class="container mx-auto">
        <div class="nav-left flex-grow hidden xl:flex" @click="activeLinks">
          <img src="@/assets/Logo.png" alt="Inluxus Logo" />
          <div id="nav-links" class="hidden xl:flex">
            <a href="/#" :class="{ 'active': activeLink == '' && $route.name == 'Home' }">Home</a>
            <a href="#about" :class="{ 'active': activeLink == '#about' }">About Us</a>
            <a href="#services" :class="{ 'active': activeLink == '#services' }">Services</a>
            <a href="#our-team" :class="{ 'active': activeLink == '#our-team' }">Our Team</a>
            <!-- <a href="/blog" :class="{
              'active': activeLink == '#blog' ||
              $route.name == 'BlogView' ||
              $route.name == 'BlogIndex'
            }">Blog</a> -->
          </div>
        </div>
        <div class="nav-right flex-grow">
          <div class="social text-grey-100 hidden xl:flex">
            <a href="#"><i class="fab fa-facebook"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/inluxusglobal/"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-linkedin"></i></a>
          </div>
          <div class="contact hidden xl:flex">
            <a href="#contact">
              <button class="button">
                <span>Contact Us</span>
                <span class="hithere"><i class="fa fa-envelope"></i></span>
              </button>
            </a>
          </div>

          <div id="mobile-nav-button" class="block xl:hidden" @click="toggleMobileNav()">
            <div v-html="navBtnClass"></div>
          </div>

        </div>
      </nav>
      <nav id="nav-mobile" class="fixed xl:none py-8" :class="{ active: mobileNav, inactive: !mobileNav }" v-if="mobileNav" @click="toggleMobileNav()">
        <div class="block w-screen text-center">
          <div class="contact flex justify-center mb-8">
            <a href="#contact">
              <button class="button">
                <span>Contact Us</span>
                <span class="hithere"><i class="fa fa-envelope"></i></span>
              </button>
            </a>
          </div>
          <p><a href="#" :class="{ 'active': activeLink == '' }">Home</a></p>
          <p><a href="#about" :class="{ 'active': activeLink == '#about' }">About Us</a></p>
          <p><a href="#services" :class="{ 'active': activeLink == '#services' }">Services</a></p>
          <p><a href="#our-team" :class="{ 'active': activeLink == '#our-team' }">Our Team</a></p>
          <!-- <p><a href="#blog" :class="{ 'active': activeLink == '#blog' }">Blog</a></p> -->
          <div class="social text-grey-100 flex justify-center">
            <a href="#" class="p-4"><i class="fab fa-facebook fa-2x"></i></a>
            <a href="#" class="p-4"><i class="fab fa-twitter fa-2x"></i></a>
            <a href="https://www.instagram.com/inluxusglobal/" class="p-4"><i class="fab fa-instagram fa-2x"></i></a>
            <a href="#" class="p-4"><i class="fab fa-linkedin fa-2x"></i></a>
          </div>
        </div>
      </nav>
    </div>
    <router-view class="mt-40" />

    <footer>
      <div class="flex flex-col lg:flex-row gap-12 justify-center py-24">
        <div class="block text-center">
          <img src="@/assets/Logo.png" alt="Inluxus Logo" class="w-max mx-auto" />
          <p>Inluxus Forte dei Marmi</p>
        </div>

        <div class="block text-left mx-auto lg:mx-0">
          <p class="mb-2"><i class="fa fa-chevron-right"></i> <a href="/#about">About Us</a></p>
          <p class="mb-2"><i class="fa fa-chevron-right"></i> <a href="/#services">Services</a></p>
          <p class="mb-2"><i class="fa fa-chevron-right"></i> <a href="/#our-team">Meet The Team</a></p>
          <p class="mb-2"><i class="fa fa-chevron-right"></i> <a href="/#contact">Contact Us</a></p>
          <p class="mb-2"><i class="fa fa-chevron-right"></i> <a href="/blog">Blog</a></p>
        </div>

        <div class="block text-center">
          <h5>Join Our Newsletter</h5>
          <div class="relative">
            <input type="email" v-model="newsletter" placeholder="Email address" />
            <label for="email">Email</label>
          </div>
          <button type="button" class="lg:float-right">Subscribe</button>
        </div>

        <div class="block p-3 text-center">
          <a href="https://fb.com/inluxus" class="m-2"><i class="fab fa-facebook fa-2x"></i></a>
          <a href="https://twitter.com/inluxus" class="m-2"><i class="fab fa-twitter fa-2x"></i></a>
          <a href="https://www.instagram.com/inluxusglobal/" class="m-2"><i class="fab fa-instagram fa-2x"></i></a>
          <a href="https://linkedin.com/inluxus" class="m-2"><i class="fab fa-linkedin fa-2x"></i></a>
        </div>
      </div>
      <div class="text-sm text-center">
        <p class="pb-4">
          <a href="/privacy-policy">Privacy Policy</a> |
          <a href="/cookie-settings">Cookie Settings</a> |
          <a href="/terms-conditions">Terms and Conditions</a> |
          <a href="/sitemap.xml">Sitemap</a>
        </p>
        <p class="pb-4">
          &copy; {{ new Date().getFullYear() }} All rights reserved
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      activeLink: '',
      contentUrl: 'https://inluxus.content.netlocomotion.com',
      mobileNav: false,
      navBtnClass: '<i class="fa fa-bars" ></i>',
      newsletter: '',
    }
  },
  mounted() {
    window.addEventListener('scroll', this.navScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.navScroll);
  },
  methods: {
    activeLinks: function(e) {
      this.activeLink = e.target.hash;
    },
    navScroll: function() {
      // Start with a blank active link
      var activeLink = '';

      // Get all section scrollTops
      var sections = document.getElementsByTagName('section');
      for (let section of sections) {
        if (window.scrollY >= section.offsetTop) {
          activeLink = '#' + section.id;
        }
      }
      this.activeLink = activeLink;
    },
    toggleMobileNav: function() {
      // Set the button symbol
      if (this.mobileNav) {
        this.navBtnClass = '<i class="fa fa-bars" ></i>';
      } else {
        this.navBtnClass = '<i class="fa fa-times" ></i>';
      }

      this.mobileNav = !this.mobileNav;
    },
  },
}
</script>

<style>
/** Font Awesome **/
$fa-font-path: "/webfonts";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
/* @import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss"; */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav-wrapper {
  background: #000a;
}

#nav {
  padding: 15px 0 10px 0;
  display: flex;
}

#nav .nav-left,
#nav .nav-right {
  display: flex;
  align-items: center;
}

#nav .nav-left {
  justify-content: left;
}

#nav .nav-right {
  justify-content: right;
}

#nav .nav-right .social {
  font-size: 20px;
  margin-right: 38px;
}

#nav .nav-right .social a {
  @apply text-grey-100;
  margin-left: 8px;
  margin-right: 8px;
}

#nav a {
  font-family: 'Montserrat', sans-serif;
  font-weight: light;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}

#nav a.router-link-exact-active,
#nav a.active {
  @apply text-gold;
}

#nav #nav-links a:hover {
	text-decoration: underline;
}

#nav-wrapper {
  height: max-content;
  z-index: 9999;
}

#nav #mobile-nav-button {
  @apply text-grey-100;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
}

#nav-mobile {
  width: 0vw;
  height: 0vh;
  background: #3a3a3aee;
  color: #fff;
  font-size: 1.5rem;
  left: 100vw;
  top: 8rem;
  overflow: hidden;
}

#nav-mobile.active {
  animation: mobileNavIn 1s ease 0.1s 1;
  animation-fill-mode: forwards;
}

#nav-mobile.inactive {
  animation: mobileNavIn 1s ease 0.1s 1;
  animation-fill-mode: backwards;
}

@keyframes mobileNavIn {
  0% {
    width: 0vw;
    height: 0vh;
    left: 100vw;
  }
  100% {
    width: 100vw;
    height: 100vh;
    left: 0;
  }
}

#nav-mobile p {
  @apply mb-4;
  font-family: 'Montserrat', sans-serif;
  font-weight: light;
}

body {
  @apply bg-black;
  @apply text-grey-200;
  overflow-x: hidden;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 27px;
  background: rgba(0, 0, 0, 0.35);
  border: 3px solid #AF9B72;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Oswald;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  text-transform: uppercase;
  color: #AF9B72;
  transition: 0.5s;
}

.button:hover {
  background-color: rgba(200, 200, 200, 0.7);
  color: #000;
}

.button span {
  margin-left: 5px;
  margin-right: 5px;
}

.button.button-secondary {
  border: none;
}

footer {
  @apply bg-grey-500;
  color: #fff;
}

footer label {
  visibility: hidden;
  position: absolute;
  left: 9px;
  @apply text-grey-100;
  font-size: small;
}

footer input {
  border: 1px solid #fff;
  background: none;
  padding: 0.5rem;
}

footer input::placeholder {
  @apply text-grey-100;
}

footer input:not(:placeholder-shown) {
  padding-bottom: 0.1rem;
  padding-top: 0.9rem;
}

footer input:not(:placeholder-shown) + label {
  visibility: visible;
}

footer button {
  @apply bg-grey-600;
  padding: 0.5rem;
  border: 1px solid #fff;
  @apply mt-3;
}

footer a:hover {
  text-decoration: underline;
}

.hithere {
  animation: hithere 5s ease 5s infinite;
}

@keyframes hithere {
  10% { transform: scale(1.2); }
  15%, 25% { transform: rotate(-20deg) scale(1.2); }
  20% { transform: rotate(20deg) scale(1.2); }
  30% { transform: rotate(0deg) scale(1.2); }
  35% { transform: scale(1); }
  100% { transform: scale(1); }
}

.moveRight {
  animation: moveRight 5s ease 5s infinite;
}

@keyframes moveRight {
  10%, 20%, 30% { transform: translateX(5px); }
  15%, 25%, 35% { transform: translateX(0px); }
  100% { transform: translateX(0px); }
}

.bounce {
  animation: bounce 0.8s linear 10ms 3;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.bobble {
  animation: bobble 0.8s linear 10ms 1;
}
@keyframes bobble {
	0%, 60%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	80% {transform: translateY(-15px);}
}
</style>

<template>
  <div class="properties">
    <div id="hero-section" class="container">
      <vue-flux
        :options="vfOptions"
        :images="vfImages"
        :transitions="vfTransitions"
        :captions="vfCaptions"
        ref="slider">

        <template v-slot:preloader>
          <flux-preloader />
        </template>

        <template v-slot:caption>
          <flux-caption />
        </template>

        <!-- <template v-slot:index>
          <flux-index />
        </template> -->
      </vue-flux>

      <div class="thumbnails flex gap-4 flex-row flex-wrap mt-4 mx-auto justify-center">
        <img :src="image" alt="Image thumbnail" v-for="(image, index) in vfImages" v-bind:key="index" @click="$refs.slider.stop(); $refs.slider.show(index)" />
      </div>
    </div>

    <section id="property" ref="property" class="pt-4 container mx-2 lg:mx-auto">
      <div class="container title mt-12">
        <p class="subtitle text-2xl">Overview</p>
        <h1>{{ property.title.rendered }} </h1>
      </div>

      <div class="container description my-8">
        <div v-html="property.content.rendered"></div>
      </div>

      <div class="container stats my-8 grid gap-4 justify-center grid-rows-1 md:grid-cols-5">
        <div class="stat-container" v-if="property.fields.total_size">
          <span class="flex items-center">
            <span class="stat-icon">
              <i class="fa fa-layer-group"> </i>
            </span>
            <span>Total Area</span>
          </span>
          <span>{{ property.fields.total_size }} m²</span>
        </div>

        <div class="stat-container" v-if="property.fields.indoor_area">
          <span class="flex items-center">
            <span class="stat-icon">
              <i class="fa fa-house"> </i>
            </span>
            <span>Indoor Area</span>
          </span>
          <span>{{ property.fields.indoor_area }} m²</span>
        </div>

        <div class="stat-container" v-if="property.fields.outdoor_area">
          <span class="flex items-center">
            <span class="stat-icon">
              <i class="fa fa-tree"> </i>
            </span>
            <span>Outdoor Area</span>
          </span>
          <span>{{ property.fields.outdoor_area }} m²</span>
        </div>

        <div class="stat-container" v-if="property.fields.bedrooms">
          <span class="flex items-center">
            <span class="stat-icon">
              <i class="fa fa-bed"> </i>
            </span>
            <span>Bedrooms</span>
          </span>
          <span>{{ property.fields.bedrooms }}</span>
        </div>

        <div class="stat-container" v-if="property.fields.bathrooms">
          <span class="flex items-center">
            <span class="stat-icon">
              <i class="fa fa-bath"> </i>
            </span>
            <span>Bathrooms</span>
          </span>
          <span>{{ property.fields.bathrooms }}</span>
        </div>
      </div>

      <div class="explore mt-48 flex flex-col md:flex-row gap-8">
        <div class="w-full md:w-1/2 border-gold border rounded-md p-8">
          <p class="subtitle text-lg">Explore</p>
          <h2 class="text-gold text-3xl">Indoor Amenities</h2>
          <div class="description" v-html="property.fields.indoor_amenities_description"></div>

          <div class="amenities flex flex-col md:flex-row flex-wrap gap-x-8 gap-y-4" v-if="property.fields.indoor_amenities">
            <div class="amenity-container" v-for="amenity in property.fields.indoor_amenities" v-bind:key="amenity.name">
              <span class="flex items-center">
                <span class="stat-icon"><i class="fa" :class="amenity.icon"></i></span>
                <span>{{ amenity.name }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 border-gold border rounded-md p-8">
          <p class="subtitle text-lg">Explore</p>
          <h2 class="text-gold text-3xl">Outdoor Amenities</h2>
          <div class="description" v-html="property.fields.outdoor_amenities_description"></div>

          <div class="amenities flex flex-col md:flex-row flex-wrap gap-x-8 gap-y-4" v-if="property.fields.outdoor_amenities">
            <div class="amenity-container" v-for="amenity in property.fields.outdoor_amenities" v-bind:key="amenity.name">
              <span class="flex items-center">
                <span class="stat-icon"><i class="fa" :class="amenity.icon"></i></span>
                <span>{{ amenity.name }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="locations mt-48">
        <div class="container">
          <p class="subtitle">Explore</p>
          <h2 class="text-gold text-3xl">Nearby Locations</h2>
          <div class="description" v-html="property.fields.location.description"></div>
        </div>

        <div class="container nearby my-8">
          <div class="nearby-container" v-if="property.fields.location.beaches">
            <h3 class="text-left text-gold ">Beaches</h3>
            <span class="heading-line"></span>

            <span class="flex md:items-center flex-col md:flex-row">
              <span class="nearby-item" v-for="beach in property.fields.location.beaches" v-bind:key="beach.name">{{ beach.name }}: {{ beach.distance }} km</span>
            </span>
          </div>
        </div>

        <div class="container nearby my-8">
          <div class="nearby-container" v-if="property.fields.location.landmarks">
            <h3 class="text-left text-gold ">Landmarks</h3>
            <span class="heading-line"></span>

            <span class="flex md:items-center flex-col md:flex-row">
              <span class="nearby-item" v-for="landmark in property.fields.location.landmarks" v-bind:key="landmark.name">{{ landmark.name }}: {{ landmark.distance }} km</span>
            </span>
          </div>
        </div>

        <div class="container nearby my-8">
          <div class="nearby-container" v-if="property.fields.location.family_fun">
            <h3 class="text-left text-gold ">Family Fun</h3>
            <span class="heading-line"></span>

            <span class="flex md:items-center flex-col md:flex-row">
              <span class="nearby-item" v-for="family_fun in property.fields.location.family_fun" v-bind:key="family_fun.name">{{ family_fun.name }}: {{ family_fun.distance }} km</span>
            </span>
          </div>
        </div>

        <div class="container nearby my-8">
          <div class="nearby-container" v-if="property.fields.location.bars_restaurants">
            <h3 class="text-left text-gold ">Bars &amp; Restaurants</h3>
            <span class="heading-line"></span>

            <span class="flex md:items-center flex-col md:flex-row">
              <span class="nearby-item" v-for="bar in property.fields.location.bars_restaurants" v-bind:key="bar.name">{{ bar.name }}: {{ bar.distance }} km</span>
            </span>
          </div>
        </div>

        <div class="container nearby my-8">
          <div class="nearby-container" v-if="property.fields.location.shopping">
            <h3 class="text-left text-gold ">Shopping</h3>
            <span class="heading-line"></span>

            <span class="flex md:items-center flex-col md:flex-row">
              <span class="nearby-item" v-for="shop in property.fields.location.shopping" v-bind:key="shop.name">{{ shop.name }}: {{ shop.distance }} km</span>
            </span>
          </div>
        </div>
      </div>

      <div class="about mt-48" v-if="property.fields.about">
        <div class="container">
          <p class="subtitle">Discover</p>
          <h2 class="text-gold text-3xl">More About {{ property.title.rendered }}</h2>

          <div>
            <div class="flex flex-col md:flex-row mb-8 border-b border-gray-700" v-for="about in property.fields.about" v-bind:key="about.section_name">
              <div class="w-full md:w-1/2">
                <h3 class="mb-4">{{ about.section_name }}</h3>
              </div>

              <div class="w-full md:w-1/2" v-html="about.text"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="moreProperties.length > 0">
        <h3 class="mt-16">You might also like</h3>
        <div class="content grid grid-cols-1 lg:grid-cols-3 my-12 pb-2 gap-6">
          <div class="flex-1 lg:order-1 property-content" v-for="property in moreProperties" :key="property.id">
            <a :href="'/properties/' + property.slug">
              <div>
                <img
                v-if="property._embedded !== undefined && property._embedded['wp:featuredmedia'] && property._embedded['wp:featuredmedia'].length > 0"
                :src="property._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url"
                :alt="property._embedded['wp:featuredmedia'][0].alt_text"
                class="w-auto mb-8"
                />
                <h3>{{ property.title.rendered }}</h3>
                <div class="block float-right">
                  <button class="button button-secondary">
                    <span>Find Out More</span>
                    <span><i class="fa fa-arrow-right"></i></span>
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </section>


    <ContactForm />


  </div>
</template>

<script>
// @ is an alias to /src
import {
   VueFlux,
   FluxCaption,
   // FluxControls,
   // FluxIndex,
   // FluxPagination,
   FluxPreloader,
} from 'vue-flux';

import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'PropertyView',
  data() {
    return {
      animated: {
      },
      property: {
        title: {
          rendered: ''
        },
        excerpt: {
          rendered: ''
        },
        content: {
          rendered: ''
        },
        fields: {
          total_size: 0,
          indoor_area: 0,
          outdoor_area: 0,
          bedrooms: 0,
          bathrooms: 0,
          indoor_amenities_description: '',
          indoor_amenities: [
            {
              name: '',
              icon: '',
            }
          ],
          outdoor_amenities_description: '',
          outdoor_amenities: [
            {
              name: '',
              icon: '',
            }
          ],
          location: {
            description: '',
            beaches: [
              {
                name: '',
                distance: 0,
              }
            ],
            landmakrs: [
              {
                name: '',
                distance: 0,
              }
            ],
            family_fun: [
              {
                name: '',
                distance: 0,
              }
            ],
            bars_restaurants: [
              {
                name: '',
                distance: 0,
              }
            ],
            shopping: [
              {
                name: '',
                distance: 0,
              }
            ],
            about: [
              {
                section_name: '',
                text: '',
              }
            ],
          },
        },
      },
      propertyImg: '',
      contactForm: {},
      contentUrl: 'https://inluxus.content.netlocomotion.com',
      moreProperties: [],

      /* Slideshow options */
      vfOptions: {
        autoplay: true,
        lazyLoad: true,
      },
      vfImages: [
      ],
      vfCaptions: [
        'Caption for image 1',
        'Caption for image 2',
      ],
      vfTransitions: [ 'wave' ],
    }
  },
  components: {
    ContactForm,
    VueFlux,
    FluxCaption,
    // FluxControls,
    // FluxIndex,
    // FluxPagination,
    FluxPreloader,
  },
  mounted() {
    window.scrollTo(0,0);
    this.getPropertyPosts();
    this.morePosts();
    // window.addEventListener('scroll', this.scrollEvents);
  },
  methods: {
    getPropertyPosts: function() {
      fetch(this.contentUrl + '/wp-json/wp/v2/property?slug=' + this.$route.params.slug + '&_embed').then(response => response.json())
        .then((data) => {
          if (data !== undefined) {
            this.property = data[0];
          }

          if (data[0]._embedded !== undefined && data[0]._embedded['wp:featuredmedia'] !== undefined) {
            this.propertyImg = data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
          }

          if (data[0].fields.images !== undefined && data[0].fields.images.length > 0) {
            this.vfImages = data[0].fields.images.map(({ image }) => image.url);
            this.vfCaptions = data[0].fields.images.map(({ image }) => image.title !== undefined && image.title.length > 0 ? image.title : '');
          }

        });
    }, // /getPropertyPosts
    morePosts: function() {
      fetch(this.contentUrl + '/wp-json/wp/v2/property?per_page=3&_embed').then(response => response.json())
        .then((data) => {
          this.moreProperties = data;
        });
    }, // /morePosts
    randomLeft: function() {
      return Math.random() * 80;
    }, // /randomLeft
    randomDelay: function() {
      return Math.random() * 7;
    }, // /randomDelay
    scrollEvents: function() {
      // var halfScreen = window.innerHeight/2;

    }, // /scrollEvents
  },
}
</script>

<style>
.subtitle {
  color: #FFF;
  /* font-size: 1.5rem; */
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0.2rem;
}

.title h1 {
  color: #AF9B72;
  font-size: 4rem;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

section h2 {
  color: #fff;
  font-family: Merriweather, serif;
  font-size: 3rem;
  @apply mb-6;
  text-align: left;
}

section h3, section p {
  @apply text-grey-200;
  @apply mb-6;
}

section p {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  line-height: 21px;
}

#property .locations h3,
#property .about h3 {
  @apply text-gold;
  font-size: 1.375rem;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

#property h3 {
	/* text-align: center; */
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  color: #fff;
  text-transform: uppercase;
}

#property .property-content {
  @apply text-justify;
  @apply px-6;
  @apply pb-6;
  @apply bg-grey-300;
  border-radius: 50px;
}

#property .property-content {
  @apply p-8;
}

#property .property-content img {
  border-radius: 50px;
}

#contact input,
#contact textarea {
  @apply bg-grey-400;
  border: 1px solid;
  @apply border-grey-100;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 16px;
  color: #fff;
  width: 100%;
}

#contact textarea {
  height: 100%;
}

#contact label {
  visibility: hidden;
  position: absolute;
  left: 15px;
  color: #a7a7a7;
  font-size: small;
  top: 4px;
}

#contact input::placeholder,
#contact textarea::placeholder {
  @apply text-grey-100;
}

#contact input:not(:placeholder-shown),
#contact textarea:not(:placeholder-shown) {
  padding-bottom: 10px;
  padding-top: 22px;
}

#contact input:not(:placeholder-shown) + label,
#contact textarea:not(:placeholder-shown) + label {
  visibility: visible;
}

#contact .contact-details p {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

#contact .contact-details a:hover {
  text-decoration: underline;
}

#contact .contact-details svg {
  width: 3.5rem;
}

.contact-bubble {
	color: #f0f0f0;
	border-radius: 100%;
	padding: 1rem;
	display: flex;
	height: 6rem;
	width: 6rem;
	justify-content: center;
	align-items: center;
	font-size: 4rem;
  position: absolute;
  bottom: 0%;
  opacity: 0;
  animation: fadeIn 2s linear alternate infinite, rise 4s linear normal infinite;
  z-index: -1;
}

@keyframes fadeIn {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@keyframes rise {
  0%    { bottom: 0%; }
  100%  { bottom: 100%; }
}

.additional-services img:hover {
  animation: bobble 0.8s linear 10ms infinite;
}

.stat-container {
  display: flex;
  padding: 0.75rem 1rem;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.375rem;
  background: #FFF;
  align-items: center;
  justify-content: space-between;

  color: #2C2C2C;
  font-size: 0.875rem;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stat-icon {
  background-color: #AF9B7240;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 100%;
  margin-right: 1rem;
}

.stat-icon .fa {
  color: #AF9B72;
}

.nearby-item {
  color: #FFF;
  font-size: 1rem;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12rem;
  text-transform: capitalize;
}

.nearby-item::after {
  content: "\2022";
  margin: 0 1rem;
}

.nearby-item:last-child::after {
  content: '';
}

.heading-line {
	background-color: #fff;
	width: 3.625rem;
	height: 3px;
	display: block;
	border-radius: 25px;
  margin-bottom: 0.5rem;
}

.thumbnails img {
	width: 10rem;
  min-width: 10rem;
	height: 8rem;
	object-fit: cover;
  border-radius: 23px;
}

.properties #hero-section .vue-flux {
  max-height: 50vh;
}

#hero-section {
  height: auto;
}

.vue-flux .flux-caption {
	bottom: 0;
	position: absolute;
}
</style>

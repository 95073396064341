<template>
  <div class="home">
    <div id="hero-section" class="w-full lg:w-11/12 mx-auto">
      <!-- <div class="hero-overlay"></div> -->
      <div class="hero-text pl-4 lg:pl-12 pt-24">
        <h1>Iconic Global Luxury</h1>
        <p>Luxury Real Estate and Lifestyle<br />Management in <span class="text-gold gold-back">Forte dei Marmi</span></p>
        <p class="hero-button">
          <a href="#about">
            <button class="button">
              <span>Learn More</span>
              <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
            </button>
          </a>
        </p>
      </div>

      <vue-flux
        :options="vfOptions"
        :images="vfImages"
        :transitions="vfTransitions"
        :captions="vfCaptions"
        ref="slider">

        <template v-slot:preloader>
          <flux-preloader />
        </template>

        <template v-slot:controls>
          <flux-controls />
        </template>

        <template v-slot:index>
          <flux-index />
        </template>
      </vue-flux>
    </div>

    <div id="hero-overlap" class="w-2/3 mx-auto lg:container flex flex-col lg:flex-row flex-wrap">
      <div class="mx-auto text-gold">
        <p class="py-4" :class="{ bobble: animated.heroOverlap }"><img src="@/assets/unsplash_2d4lAQAlbDA_circle.png" alt="Luxury Property Image" class="mx-auto" /></p>
        <p class="py-4">Luxury Properties</p>
      </div>

      <div class="mx-auto text-gold">
        <p class="py-4" :class="{ bobble: animated.heroOverlap }" style="animation-delay: 0.5s"><img src="@/assets/unsplash_8YG31Xn4dSw_circle.png" alt="Luxury Property Image" class="mx-auto" /></p>
        <p class="py-4">24/7 Concierge Service</p>
      </div>

      <div class="mx-auto text-gold">
        <p class="py-4" :class="{ bobble: animated.heroOverlap }" style="animation-delay: 1s"><img src="@/assets/unsplash_f1WMJR8pLqo_circle.png" alt="Luxury Property Image" class="mx-auto" /></p>
        <p class="py-4">Quintessential Luxury</p>
      </div>
    </div>

    <section id="about" ref="about" class="pt-32 container mx-2 lg:mx-auto">
      <h2>About <span class="text-gold">Inluxus</span></h2>

      <div class="subheading-icons mt-8 mb-16 mx-auto grid grid-cols-2 gap-6 lg:flex">
        <div class="subheading-icons-section w-auto">
          <div class="subheading-icons-text justify-left lg:justify-center">
            <span class="icon-circle bg-green-100" :class="{ bounce: animated.subHeadIconCircle }">
              <i class="fa fa-shield-alt"></i>
            </span>
            <span>Reliable</span>
          </div>
          <div class="subheading-icons-underline bg-green-100" :class="{ animate: animated.subHeadIcons }"></div>
        </div>

        <div class="subheading-icons-section w-auto">
          <div class="subheading-icons-text justify-left lg:justify-center">
            <span class="icon-circle bg-blue-100" :class="{ bounce: animated.subHeadIconCircle }">
              <i class="fa fa-handshake"></i>
            </span>
            <span>Trustworthy</span>
          </div>
          <div class="subheading-icons-underline bg-blue-100" :class="{ animate: animated.subHeadIcons }"></div>
        </div>

        <div class="subheading-icons-section w-auto">
          <div class="subheading-icons-text justify-left lg:justify-center">
            <span class="icon-circle bg-purple-100" :class="{ bounce: animated.subHeadIconCircle }">
              <i class="fa fa-user-tie"></i>
            </span>
            <span>Professional</span>
          </div>
          <div class="subheading-icons-underline bg-purple-100" :class="{ animate: animated.subHeadIcons }"></div>
        </div>

        <div class="subheading-icons-section w-auto">
          <div class="subheading-icons-text justify-left lg:justify-center">
            <span class="icon-circle bg-pink-100" :class="{ bounce: animated.subHeadIconCircle }">
              <i class="fa fa-clock"></i>
            </span>
            <span>24/7 Availability</span>
          </div>
          <div class="subheading-icons-underline bg-pink-100" :class="{ animate: animated.subHeadIcons }"></div>
        </div>

      </div>

      <div class="content flex flex-col lg:flex-row justify-between items-start mb-4">
        <div class="text-left flex-1 order-2 lg:order-1">
          <h3>We are a <span class="text-gold">boutique</span> of <span class="text-gold">luxury real estate</span> and <span class="text-gold">lifestyle</span> management consultancy.</h3>
          <p>INLUXUS was founded by Alessandra Casalini and Armando Camacho who have extensive experience working with ultra-high net worth individuals and organisations.</p>
          <p>"We founded INLUXUS with a mission: realise our customers’ dreams and let them live precious and meaningful moments."</p>
          <p>For this reason we offer tailor-made holidays in the most amazing locations in the world. We carefully check all our properties in order to offer an outstanding collection of houses and experiences.</p>

          <div class="about-caption">
            LUXURY PROPERTY <span class="hidden lg:inline-block">&#x2022;</span> PRIVATE CONCIERGE<br />PREMIUM SERVICE
          </div>
        </div>

        <div class="flex-1 order-1 lg:order-2 mb-4">
          <img src="@/assets/about-img.png" alt="About Inluxus Image" class="ml-auto" />
        </div>
      </div>

      <div class="flex justify-center">
        <a href="#contact">
          <button class="button">
            <span>Want To Know More</span>
            <span class="hithere"><i class="fa fa-question"></i></span>
          </button>
        </a>
      </div>
    </section>


    <section id="services" ref="services" class="pt-64 container mx-2 lg:mx-auto">
      <h2>Our <span class="text-gold">Services</span></h2>

      <vue-flux
        :options="vfOptions"
        :images="vfImagesServices"
        :transitions="vfTransitions"
        :captions="vfCaptions"
        ref="slider">

        <template v-slot:preloader>
          <flux-preloader />
        </template>

        <template v-slot:controls>
          <flux-controls />
        </template>

        <template v-slot:index>
          <flux-index />
        </template>
      </vue-flux>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-2 lg:ml-6 text-center">
          <h3>Property Management</h3>
          <p>
            We offer the finest collection of luxury holiday villas available for rent
            and for sale in the world's top locations such as Côte d'Azur, Forte dei Marmi,
            Greek Islands, Balearic Islands, Sardinia, Tuscany, Italy, Spain, Swiss and
            Austrian Alps and much more.
          </p>
          <p>
            Altogether with our renowned tailored management and concierge service.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-32">
        <div class="flex-1 lg:order-2 lg:ml-6">
          <img src="@/assets/unsplash_jpHw8ndwJ_Q.png" alt="Room interior" />
        </div>
        <div class="flex-1 lg:order-1 lg:mr-6 text-center lg:text-right">
          <p class="my-6">
            We also offer a range of property services to our clients like interior
            design, relocation, private staff research, house management.
          </p>
          <p class="flex justify-center lg:justify-end">
            <a href="#contact">
              <button class="button">
                <span>Find Out More</span>
                <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
              </button>
            </a>
          </p>
        </div>
      </div>

      <div class="content flex flex-col items-center my-12 pb-8">
        <div class="flex-1 lg:order-1">
          <img src="@/assets/unsplash_LVcjYwuHQlg.png" alt="Capital" />
        </div>
        <div class="flex-1 lg:order-2 text-center pt-12">
          <h3>Golden Visa Programmes</h3>
          <p>
            Residence by investment programs, also known as golden visa programs, provide
            high-net-worth individuals with the option to relocate and the right to live,
            work, study, and receive healthcare in their new countries of residence. Being
            able to change residence to a suitable country or have multiple residences is
            an increasingly important aspect of international planning for private clients.
          </p>
          <p>
            Inluxus offers expert advice on residence by investment programs in some of the
            world’s top placesin terms of business environment, quality of life, and the
            possibility of acquiring citizenship. Listed below are the the most credible
            and successful residence programs recommended by Inluxus.
          </p>
          <p>
            Australia, Austria, Canada, Cyprus, Greece, Hong Kong, Jersey, Latvia, Italy,
            Luxembourg, Malta, Mauritius, Monaco, New Zealand, Panama, Portugal, Singapore,
            Spain, Switzerland, United Arab Emirates and Thailand.
          </p>
          <p class="flex justify-center pt-6 mb-24">
            <a href="#contact">
              <button class="button">
                <span>Make an Enquiry</span>
                <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
              </button>
            </a>
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-1 lg:mr-6">
          <img src="@/assets/concierge.png" alt="24/7 Concierge" />
        </div>
        <div class="flex-1 lg:order-2 lg:ml-6 text-left">
          <h3>VIP Concierge</h3>
          <p>
            Our specially trained multi-lingual staff is on hand to help with general
            advice, bookings and assistance. Whether you’re making a dinner reservation
            or planning a long holiday abroad, our member assistants are fast, efficient
            and highly trained to offer the best possible service.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-2 lg:ml-6">
          <img src="@/assets/transfers.png" alt="Transfers" />
        </div>
        <div class="flex-1 lg:order-1 lg:mr-6 text-right">
          <h3>Transfers and Car Rental</h3>
          <p>
            We can arrange a private transfer with our official registered transfer companies.
            Ensure your trip gets off to the best possible start by letting us provide you
            luxury airport transfers and chauffeur services.
          </p>
          <p>
            We also offer the best luxury car rental you can find in the market: Ferrari,
            Lamborghini, Jaguar, Bentley, Aston Martin and many others.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-1 lg:mr-6">
          <img src="@/assets/yacht.png" alt="Yacht Charter" />
        </div>
        <div class="flex-1 lg:order-2 lg:ml-6 text-left">
          <h3>Yacht Charter</h3>
          <p>
            Whether its for the day or for a week, a yacht gives you total freedom and we
            understand that details are important, which is why we assure you that all of the
            yachts are in immaculate condition and are run by the most experienced, professional
            and friendly crew in the industry. From giving tips on the best places to eat, to
            the best diving spots, to making sure your favourite wine label is on board, our
            team make every effort to ensure that every tiny detail is taken care of to create your.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-2 lg:ml-6">
          <img src="@/assets/flights.png" alt="Private Flights" />
        </div>
        <div class="flex-1 lg:order-1 lg:mr-6 text-right">
          <h3>Private Flights</h3>
          <p>
            For the discreet and private customers who wish to travel in ultimate style, seeking
            unparalleled luxury private flights are the best option . Let us take you from your
            door to anywhere in the world.
          </p>
          <p>
            State of the art aircrafts including: helicopters, jets and turboprops with experienced
            multilingual staff across five time zones, working out of offices in Europe, CIS, the
            Middle East, Asia, and America, we are confident we will offer a true quality experience.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-1 lg:mr-6">
          <img src="@/assets/shopping.png" alt="Personal Shopping" />
        </div>
        <div class="flex-1 lg:order-2 lg:ml-6 text-left">
          <h3>Personal Shopping</h3>
          <p>
            Our stylists and personal shopping assistants understand the fashion scene inside out and
            will ensure that all your needs are met promptly and delicately by assisting you directly
            on your shopping spree.
          </p>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center my-12 pb-8">
        <div class="flex-1 lg:order-2 lg:ml-6">
          <img src="@/assets/wine.png" alt="Wine Tasting" />
        </div>
        <div class="flex-1 lg:order-1 lg:mr-6 text-right">
          <h3>Wine Tasting Experiences</h3>
          <p>
            Integrate art and pleasure during your wine tasting, magical places where you can sample
            the best examples of Italian Dolce Vita in a bottle, everything experienced in traditional,
            functional and established environments such as a wine cellars and vineyards. You can choose
            from worldwide renowned vineyards such as Antinori, Ornellaia and Sassicaia. The service
            includes; guided visit of the chosen location, wine tasting and professional explanations.
          </p>
          <p>
            We also have access to some of the finest and most exquisite wines in the world that can
            be a unique piece in your wine cellar.
          </p>
        </div>
      </div>

      <div class="flex justify-center mb-36">
        <a href="#contact">
          <button class="button">
            <span>Find Out More</span>
            <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
          </button>
        </a>
      </div>

      <div class="content flex flex-col items-center mt-12">
        <div class="flex-1 lg:order-1">
          <img src="@/assets/capital.png" alt="Capital" />
        </div>
        <div class="flex-1 lg:order-2 text-center pt-12">
          <h3>Capital</h3>
          <p>
            Luxury goods might become to best assets to invest on in the coming years.
            Almost recession-proof, the flourishing luxury goods market is still one of the
            most successful industry worldwide and has a very bright future ahead. With
            a high profitability in a short period of time, it is and is going to be the
            biggest rival of real estate or stock market investments for wealthy people.
            It’s even more interesting for people living in emerging countries were financial
            markets and domestic currencies are not stable. The most interesting category of
            luxury goods to invest in is obviously the one that can bring huge profits. This
            category gathers in general collector’s items, rare and vintage goods, limited
            editions, very old piece of history, discontinued products, high demanded products.
          </p>
          <p>
            We provide our clients with a highly unique and specialized service in the Investment sector.
          </p>
          <p>
            Investments, such as Diamonds &amp; Jewellery, Fine Art &amp; Antiques, Fine Wine
            &amp; Whisky, Racehorses, Exclusive off-market Real Estate, Classic Cars and Other
            Collectibles are our core competencies - assets for which a significant surge in
            global demand is currently being experienced.
          </p>
          <p class="flex justify-center pt-6">
            <a href="#contact">
              <button class="button">
                <span>Get In Touch</span>
                <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
              </button>
            </a>
          </p>
        </div>
      </div>
    </section>

    <section id="our-team" ref="our-team" class="pt-64 container mx-2 lg:mx-auto">
      <h2>Our <span class="text-gold">Team</span></h2>

      <div class="content flex flex-col lg:flex-row items-center mt-12">
        <div class="flex-1 lg:order-1 lg:mr-6 mb-16">
          <img src="@/assets/team_photo_1.png" alt="Alessandra Casalini" class="mx-auto -mb-32" />
          <div class="team-content">
            <h3>Alessandra Casalini</h3>
            <p>
              Alessandra Casalini is the CEO of the company. Having graduated
              from Nottingham Trent University in Marketing and Communication Studies,
              she is an instrumental figure in the group. With her first company
              FDM RE, founded in 2007, she had beed a pioneering force behind the
              development of Russian and Ukrainian property market in Italy in
              particular in the locations of Forte dei Marmi, Sardinia, Bolgheri
              and Garda Lake. As a real estate professional, she recognises the
              value and the trust her clients place her in and she strives everyday
              to exceed their expectations. Her priority is her clients' satisfaction,
              she listens to what they want and their needs to achieve the best
              outcome in every transaction and she strives to ensure that the
              home-buying or selling process is fun, easy and stress-free. Her
              strong entrepreneurial edge has been the driving force in establishing
              INLUXUS with her business partner Armando Camacho in order to complete
              their new vision to transform the company in a truly influential
              Italian brand capable to penetrate new emerging markets like Mexico,
              Brazil, China and India and offer high level services to consolidated
              mature markets such as USA, Canada, Japan and Korea.
            </p>
            <div class="text-center text-white" mb-8>
              <a href="https://www.instagram.com/alessandrainluxus/"><i class="fab fa-instagram text-lg mx-4"></i></a>
              <a href="https://www.linkedin.com/in/alessandra-casalini-84655226/"><i class="fab fa-linkedin text-lg mx-4"></i></a>
            </div>
          </div>
        </div>
        <div class="flex-1 lg:order-1 lg:mr-6 mb-16">
          <img src="@/assets/team_photo_2.png" alt="Armando Camacho" class="mx-auto -mb-32" />
          <div class="team-content">
            <h3>Armando Camacho</h3>
            <p>
              Armando Camacho is an entrepreneur and strategic marketing professional
              who has lived in 10 countries and more than 27 cities (UK, Italy,
              Holland, Canada, Mexico, USA, France, England, Greece and Spain)
              He is part of a Mexican-Spanish family that has achieved wonderful
              milestones in the marketing and publicity world in USA, Europe and
              Mexico. Knowledgeable in diamonds and precious stones. Armando has
              worked closely in the yacht world and industry while living in Fort
              Lauderdale, Miami, Monaco, Palma de Mallorca, Boston, Los Angeles,
              Cancun, Cozumel and Athens. Had the opportunity to work directly with
              captains and owners of the yachts and mega-yachts keeping a close
              business and friendly relationship with them. A high-touch real estate
              agent known for his extensive market knowledge and his unmatched
              devotion to clients. Had been working in the real estate business
              in the Caribbean, American continent, Côte d'Azur, Greece and Italy
              for the past years. Concentrating in off-market properties for UHNWIs
              and companies in a worldwide scale. Armando brings excellent customer
              service and a commitment to work hard, listen and follow through.
            </p>
            <div class="text-center text-white" mb-8>
              <a href="https://www.linkedin.com/in/armando-camacho-583380a/"><i class="fab fa-linkedin text-lg mx-4"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="content flex flex-col lg:flex-row items-center justify-center lg:mt-12">
        <div class="w-full lg:w-1/2 lg:order-1 mb-16">
          <img src="@/assets/team_photo_3.png" alt="Iryna Zayats" class="mx-auto -mb-32" />
          <div class="team-content">
            <h3>Iryna Zayats</h3>
            <p>
              Iryna Zayats is an experienced consultant in luxury real estate and lifestyle
              management. Dynamic, determined and conscientious, she brings a vast wealth of
              real estate knowledge and experience to the benefit of the clientele. Her
              passion for excellence is only equalled by her passion to offer to the clients
              the most informed and unbiased advice so they will be able to complete a successful
              transaction. Iryna, thanks to her brilliant qualities, has cultivated a loyal
              clientele who value her work ethics and results.
            </p>
            <div class="text-center text-white" mb-8>
              <a href="https://www.linkedin.com/in/"><i class="fab fa-linkedin text-lg mx-4"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <a href="#contact">
          <button class="button">
            <span>Get In Touch</span>
            <span class="hithere"><i class="fa fa-comment"></i></span>
          </button>
        </a>
      </div>
    </section>

    <!-- <section id="blog" ref="blog" class="pt-64 container mx-2 lg:mx-auto">
      <h2>Latest <span class="text-gold">News</span></h2>

      <div class="content grid grid-cols-1 lg:grid-cols-2 my-12 pb-2 gap-6">
        <div class="flex-1 lg:order-1 blog-content" v-for="blog in blogs" :key="blog.id">
          <div>
            <img
              v-if="blog._embedded['wp:featuredmedia'] && blog._embedded['wp:featuredmedia'].length > 0"
              :src="blog._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url"
              :alt="blog._embedded['wp:featuredmedia'][0].alt_text"
              class="w-auto mb-8"
            />
            <h3>{{ blog.title.rendered }}</h3>
            <span v-html='blog.excerpt.rendered'></span>
            <div class="block float-right">
              <router-link :to="'/blog/' + blog.slug">
                <button class="button button-secondary">
                  <span>Read More</span>
                  <span><i class="fa fa-arrow-right"></i></span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <router-link to="/blog">
          <button class="button">
            <span>See More Posts</span>
            <span class="moveRight"><i class="fa fa-arrow-right"></i></span>
          </button>
        </router-link>
      </div>
    </section> -->


    <ContactForm />

  </div>
</template>

<script>
// @ is an alias to /src
import {
   VueFlux,
   // FluxCaption,
   FluxControls,
   FluxIndex,
   // FluxPagination,
   FluxPreloader,
} from 'vue-flux';

import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'Home',
  data() {
    return {
      animated: {
        heroOverlap: false,
        subHeadIcons: false,
        subHeadIconCircle: false,
      },
      blogs: {},
      contactForm: {},
      contentUrl: 'https://inluxus.content.netlocomotion.com',

      /* Slideshow options */
      vfOptions: {
        autoplay: true,
        lazyLoad: true,
      },
      vfImages: [
        'slides/img1.png',
        'slides/img2.png',
        'slides/img1.jpg',
        'slides/img2.jpg',
        'slides/img3.jpg',
        'slides/img4.jpg',
        'slides/img5.jpg',
        'slides/img6.jpg',
        'slides/img7.jpg',
        // 'slides/img3.png',
        // 'slides/img4.png'
      ],
      vfImagesServices: [
        'slides/img1.jpg',
        'slides/img8.jpg',
        'slides/img9.jpg',
        'slides/img10.jpg',
        'slides/img11.jpg',
      ],
      vfTransitions: [ 'wave' ],
      vfCaptions: [
        'Caption for image 1',
        'Caption for image 2',
      ],
    }
  },
  components: {
    VueFlux,
    // FluxCaption,
    FluxControls,
    FluxIndex,
    // FluxPagination,
    FluxPreloader,
    ContactForm,
  },
  mounted() {
    window.scrollTo(0,0);
    this.getBlogPosts();
    window.addEventListener('scroll', this.scrollEvents);
  },
  methods: {
    getBlogPosts: function() {
      fetch(this.contentUrl + '/wp-json/wp/v2/posts?per_page=2&_embed').then(response => response.json())
        .then((data) => this.blogs = data);
    }, // /getBlogPosts
    randomLeft: function() {
      return Math.random() * 80;
    }, // /randomLeft
    randomDelay: function() {
      return Math.random() * 7;
    }, // /randomDelay
    scrollEvents: function() {
      var halfScreen = window.innerHeight/2;

      if (this.$route.name == 'Home') {
        // Sub Header Icons
        var subHeadIcons = document.getElementsByClassName('subheading-icons')[0];
        if (window.scrollY >= (subHeadIcons.offsetTop - halfScreen)) {
          this.animated.subHeadIcons = true;
          this.animated.subHeadIconCircle = true;
        }

        // Hero Overlap Images
        var heroOverlap = document.getElementById('hero-overlap');
        if (window.scrollY >= (heroOverlap.offsetTop - halfScreen)) {
          this.animated.heroOverlap = true;
        }
      }
    }, // /scrollEvents
  },
}
</script>

<style>
section h2 {
  color: #fff;
  font-family: Merriweather, serif;
  font-size: 3rem;
  @apply mb-6;
  text-align: center;
}

section h3, section p {
  @apply text-grey-200;
  @apply mb-6;
}

section p {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  line-height: 21px;
}

#about h3 {
  font-family: Merriweather, serif;
  font-size: 1.5rem;
}

#services h3 {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  color: #fff;
}

#services h4 {
  font-family: Montserrat, sans-serif;
  font-size: 1.125rem;
  color: #fff;
  margin-top: 1rem;
  text-align: center;
}

#our-team h3,
#blog h3 {
	text-align: center;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  color: #fff;
  text-transform: uppercase;
}

.vue-flux .complements, .vue-flux > .flux-image {
	border-radius: 50px;
}

#hero-section {
  position: relative;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), url('../assets/img1.png'); */
  height: 100vh;
  background-position: center right;
  background-size: cover;
  border-radius: 50px;
}

#hero-section .vue-flux {
  height: 100vh;
}

@screen lg {
  #hero-section {
    height: 80vh;
  }

  #hero-section .vue-flux {
    height: 80vh;
  }
}

.hero-button button {
  background-color: rgba(0, 0, 0, 0.7);
}

.gold-back {
	background: #00000055;
	padding: 0px 5px;
	border-radius: 10px;
}

#hero-section .hero-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.15);
	z-index: 20;
	border-radius: 50px;
}

.vue-flux .flux-controls {
  margin-top: 60vh;
}

.vue-flux .flux-controls .play,
.vue-flux .flux-controls .pause {
	display: none;
}

#hero-section .hero-text {
  font-family: Merriweather, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 2.75rem;
  color: #fff;
  text-align: left;
  text-shadow: #000 0px 0px 10px;
  position: absolute;
  display: block;
  z-index: 99;
}

#hero-section .hero-text h1 {
  font-family: Merriweather, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: 4.5rem;
  color: #fff;
  text-align: left;
}

@screen lg {
  #hero-section .hero-text h1 {
    font-size: 4.5rem;
    line-height: 5.75rem;
  }
}


#hero-section .hero-text .hero-button {
  margin-top: 2.125rem;
}

#hero-overlap {
  display: flex;
  position: relative;
  z-index: 999;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  /* padding: 57px 116px; */
  padding: 2rem 1rem;
  font-family: Merriweather, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #fff;
  text-align: center;

  /* background: rgba(43, 47, 50, 0.7);
  box-shadow: 0px 8px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border-radius: 50px;
  margin-top: 8rem; */
}

/* @screen lg {
  #hero-overlap {
    margin-top: -8rem;
  }
} */

.subheading-icons {
  color: #fff;
  /* display: grid; */
  justify-content: space-between;
}

.subheading-icons .subheading-icons-section .subheading-icons-text .icon-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  color: #fff;
}

.subheading-icons .subheading-icons-section .subheading-icons-text {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.subheading-icons .subheading-icons-section .subheading-icons-text span {
	margin-left: 5px;
	margin-right: 5px;
  font-family: Montserrat, sans-serif;
}

.subheading-icons .subheading-icons-section .subheading-icons-underline {
	height: 3px;
	width: 10%;
	margin-top: 8px;
  margin-left: 60%;
  margin-right: auto;
}

.subheading-icons .subheading-icons-section .subheading-icons-underline.animate {
  animation: growLine 2s forwards 1;
}

@keyframes growLine {
  0% {
    width: 10%;
    margin-left: 60%;
  }
  100% {
    width: 100%;
    margin-left: 0%;
  }
}

.about-caption {
  @apply text-gold;
  text-align: center;
  font-family: Merriweather, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
}

#our-team .team-content,
#blog .blog-content {
  @apply text-justify;
  @apply px-6;
  @apply pb-6;
  @apply bg-grey-300;
  border-radius: 50px;
}

#our-team .team-content {
  padding-top: 9.5rem;
}

#our-team .team-content p {
  @apply mb-8;
}

#blog .blog-content {
  @apply p-8;
}

#blog .blog-content img {
  border-radius: 50px;
}

@keyframes fadeIn {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@keyframes rise {
  0%    { bottom: 0%; }
  100%  { bottom: 100%; }
}

.additional-services img:hover {
  animation: bobble 0.8s linear 10ms infinite;
}
</style>
